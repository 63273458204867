<template>
  <b-dropdown id="locale-dropdown" right no-caret>
    <template #button-content>
      <span class="button--content font-weight-bold text-uppercase">
        {{ locale }}
      </span>
    </template>
    <template v-for="(locale, index) in localeOptions">
      <b-dropdown-item
        :key="index"
        class="font-weight-bold"
        :active="isActiveLocale(locale.value)"
        @click="!isActiveLocale(locale.value) && changeLocale(locale.value)"
      >
        {{ locale.text }}
      </b-dropdown-item>
    </template>
  </b-dropdown>
</template>

<script>
export default {
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    localeOptions() {
      return this.$i18n.availableLocales.map(locale => ({
        value: locale,
        text: this.getLocaleText(locale)
      }));
    }
  },
  methods: {
    getLocaleText(locale) {
      switch (locale) {
        case "it":
          return "Italian";
        case "en":
          return "English";
        case "fr":
          return "French";
        case "es":
          return "Spanish";
        case "de":
          return "German";
        case "zh":
          return "Chinese";
        default:
          return locale;
      }
    },
    isActiveLocale(locale) {
      return this.$i18n.locale === locale;
    },
    changeLocale(locale) {
      this.$i18n.locale = locale;
      const to = this.$router.resolve({ params: { lang: this.$i18n.locale } });
      this.$router.replace(to.location);
    }
  }
};
</script>

<style lang="scss" scoped>

#locale-dropdown {
  border-left: 1px solid #2d3648;
  padding-left: 8px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  /deep/ {
    button {
      width: 100%;
      text-align: center;
      box-shadow: unset;
      flex: unset;
      border-radius: unset;
      background-color: transparent; // Set background color to transparent

      .button--content {
        font-size: 14px;
        font-weight: bold;
        color: #2d3648; // Ensure text color is set
      }
    }

    .dropdown-menu {
      min-width: unset;
    }

    .dropdown-item {
      font-weight: bold;
      color: #2d3648;

      &:hover {
        background-color: var(--emotiva-pink);
        color: #fff; // Optional: Change text color on hover as well
      }

      &.active {
        background-color: var(--emotiva-pink);
        color: #fff; // Optional: Change text color when active as well
      }
    }
  }
}
@media screen and (max-width: 991px) {
  #locale-dropdown {
    border-left: unset;
    padding-left: 0;
    /deep/ {
      button {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
</style>

