<template>
  <footer class="bg-white-primary">
    <b-row class="no-gutters pl-0 pl-md-5 py-5 pr-5 footer">
      <b-col
        v-for="(footerItem, i) in footerInfoList"
        :key="i"
        class="col-12 col-md-4  text-uppercase"
        :class="footerItem.footerClass"
      >
        <h5 class="mb-3 title__3">
          {{ $t(`footer.${footerItem.categoryName}`) }}
        </h5>
        <h5
          v-for="(linkItem, j) in footerItem.linkList"
          :key="j"
          class="subtitle__1"
        >
          <b-link
            target="_blank"
            :href="getExternalLink(linkItem)"
            class=""
          >
            {{ $t(`footer.${linkItem.text}`) }}
          </b-link>
        </h5>
      </b-col>
      <b-col class="col-12 col-md-auto ml-auto mt-md-0 mt-5 ">
        <b-link
          target="_blank"
          :href="getExternalLink(privacy)"
          class=" subtitle__1 link"
        >
          {{ $t("footer.privacy") }}
        </b-link>
        {{ " | " }}
        <b-link
          target="_blank"
          :href="getExternalLink(terms)"
          class=" subtitle__1 link"
        >
          {{ $t("footer.terms") }}
        </b-link>
        <h5
          class="subtitle__1 mt-2"
          v-html="$t('footer.copyright', { year: new Date().getFullYear() })"
        ></h5>
      </b-col>
    </b-row>
  </footer>
</template>

<script>
import Utils from "../Utils";
export default {
  data() {
    return {
      footerInfoList: [
        {
          categoryName: "support",
          linkList: [
            {
              text: "gettingStarted",
              hrefEn: "https://emotiva.it/en/foresight-metrics/",
              hrefIt: "https://emotiva.it/en/foresight-metrics/"
            },
            // {
            //   text: "settingStudy",
            //   hrefEn: "https://emotiva.it/en/empower-setting-up-a-study/",
            //   hrefIt: "https://emotiva.it/empower-creare-uno-study/"
            // },
            // {
            //   text: "audience",
            //   hrefEn: "https://emotiva.it/en/empower-audience/",
            //   hrefIt: "https://emotiva.it/empower-audience-2/"
            // },
            // {
            //   text: "analyzeResults",
            //   hrefEn: "https://emotiva.it/en/empower-analyze-results/",
            //   hrefIt: "https://emotiva.it/empower-analizzare-i-risultati/"
            // }
          ],
          footerClass: "pr-0 pr-md-3"
        },
        // {
        //   categoryName: "company",
        //   linkList: [
        //     {
        //       text: "solutions",
        //       hrefEn: "https://emotiva.it/empower-predictive-media-analytics",
        //       hrefIt: "https://emotiva.it/empower-predictive-media-analytics"
        //     },
        //     {
        //       text: "technology",
        //       hrefEn: "https://emotiva.it/emotion-recognition",
        //       hrefIt: "https://emotiva.it/emotion-recognition"
        //     }
        //     // { text: "caseStudies" }
        //   ],
        //   footerClass: "mt-5 mt-md-0"
        // }
      ],
      privacy: {
        hrefEn: "https://emotiva.it/en/emotiva-gdpr-and-privacy-en/",
        hrefIt: "https://emotiva.it/emotiva-gdpr-e-privacy/"
      },
      terms: {
        hrefEn: "https://emotiva.it/en/saas-terms-conditions/",
        hrefIt: "https://emotiva.it/condizioni-di-licenza/"
      },
      year: 2021
    };
  },
  methods: {
    getExternalLink(linkItem) {
      return Utils.getExternalLinkByLang({
        linkEn: linkItem.hrefEn,
        linkIt: linkItem.hrefIt
      });
    }
  }
};
</script>

<style scoped>
.link {
  text-decoration: unset;
  width: fit-content;
}
.link:hover {
  text-decoration: underline;
}
footer {
  padding-left: 60px;
  border-top:3px solid #2D3648;
}
</style>
