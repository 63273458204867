<template>
  <b-modal
    v-model="isModalVisible"
    size="md"
    dialog-class="mt-md-5 p-0"
    content-class="p-2"
    footer-class="border-top-0"
    header-class="border-bottom-0"
    @ok="submit"
    @cancel="cancel"
  >
    <template v-slot:modal-header>
      <h5 class="mb-0 text-uppercase font-weight-bold">
        {{ title }}
      </h5>
    </template>

    <template v-slot:default>
      <p class="label">
        {{ bodyText }}
      </p>
    </template>

    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button
        variant="white"
        class="mr-3 btn-blue-border justify-content-center align-items-center bold action-btn cancel"
        @click="cancel"
        >{{ $t("button.cancel") }}</b-button
      >
      <b-button
        :variant="variant"
        class="justify-content-center align-items-center bold action-btn"
        @click="ok"
        >{{ $t("button.ok") }}</b-button
      >
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    bodyText: {
      type: String,
      required: true,
    },
    modalStatus: {
      type: Boolean,
      required: true,
    },
    variant: {
      type: String,
      required: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    isModalVisible: {
      get() {
        return this.modalStatus;
      },
      set(modalStatus) {
        this.$emit("setModalStatus", modalStatus);
      },
    },
  },
  methods: {
    submit() {
      // create folder
      this.$emit("submit");
    },
    cancel() {
      this.$emit("cancel", "");
    },
  },
};
</script>

<style scoped>
.action-btn {
  border-radius: 26px;
  height: 46px;
  min-width: 100px;
}
.cancel {
  border: 2px solid #cbd2e0;
  /* Change border-width to 2px */
  border-color: #cbd2e0;
}
</style>
