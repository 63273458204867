<template>
  <b-navbar
    toggleable="lg"
    type="light"
    class="bg-white-primary"
    id="main-menu"
  >
    <b-navbar-brand href="#" @click="goToDashboard">
      <div class="d-flex justify-content-center align-items-center ml-1">
        <img src="../assets/menu-icons/MENU_logo.png" class="ml-4" alt="">
      </div>
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item 
          class="px-md-2 center-align" 
          v-if="isLoggedIn"
          @click="redirectToPage('Projects')"
          :class="{ 'active-link': isRouteActive('Projects') || isRouteActive('Dashboard') }"
        >
          <span class="bold">
            {{ $t("mainMenu.projects") }}
          </span>
        </b-nav-item>
        
        <template v-if="isLoggedIn">
          <b-nav-item
            class="px-md-2 center-align"
            :class="{ 'active-link': isRouteActive('Account') }"
            @click="redirectToPage('Account')"
            v-if="isAllowedAccountRoute"
          >
            <img
              class="circle-img mr-2"
              src="../assets/user/default-user-icon.png"
              alt=""
            />
            <span>{{ $t("sideMenu.account") }}</span>
          </b-nav-item>
          <b-nav-item class="px-md-2 center-align" @click="logout">
            {{ $t("mainMenu.logout") }}
          </b-nav-item>
        </template>
        
        <b-nav-item class="px-md-2 d-flex justify-content-start align-items-center">
          <base-locale-switcher />
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import Utils from "../Utils";
import BaseLocaleSwitcher from "./Base/BaseLocaleSwitcher";

export default {
  components: {
    BaseLocaleSwitcher,
  },
  props: {
    isLoggedIn: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      publicMenu: [
        {
          name: "support",
          routeName: "Support",
          isExternalURL: true,
          linkEn: "https://emotiva.it/en/empower-getting-started/",
          linkIt: "https://emotiva.it/empower-per-iniziare/",
        },
      ],
      privateMenu: [
        {
          name: "projects",
          routeName: "Projects",
          isExternalURL: false,
          linkEn: "",
          linkIt: "",
        },
      ],
    };
  },
  
  methods: {
    startTour() {
      this.$store.commit("settings/SET_TOUR_ACTIVE", true);
    },
    logout() {
      this.$store.dispatch("logout", null, { root: true });
    },
    goToDashboard() {
      if (this.$route.name !== "Dashboard" && this.$route.name !== "Login") {
        const routeName = this.$store.getters["auth/isLoggedIn"]
          ? "Dashboard"
          : "Login";
        this.$router.push({ name: routeName });
      }
    },
    redirectToPage(route, isExternalURL, linkEn, linkIt) {
      if (isExternalURL) {
        window.open(
          Utils.getExternalLinkByLang({
            linkEn,
            linkIt,
          }),
          "_blank"
        );
      } else this.$router.push({ name: route });
    },
    isRouteActive(routeName) {
      return this.$route.name === routeName;
    },
  },
  computed: {
    mainMenu() {
      const menu = this.isLoggedIn
        ? this.publicMenu.concat(this.privateMenu)
        : this.publicMenu;
      return menu;
    },
    isTourLinkVisible() {
      return (
        this.$route.name === "Dashboard" || this.$route.name === "Projects"
      );
    },
    isAllowedAccountRoute() {
      const isMultiUser = this.$store.getters["auth/isMultiUser"];
      const userType = this.$store.getters["auth/getUserType"];
      const userSubType = this.$store.getters["auth/getUserSubType"];

      if (userType === "custom" && isMultiUser) {
        return userSubType === "admin";
      }
      return true;
    },
  },
};
</script>

<style scoped>
#main-menu {
  background-color: #fff;
}

#nav {
  text-align: center;
  padding: 30px;
}

#logo {
  font-size: large;
  font-weight: bold;
  border-radius: 52px;
  background-color: #edf0f7;
  height: 40px;
  width: 100px;
}

#nav a {
  font-weight: bold;
  color: #2d3648;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#nav-collapse a {
  font-size: 18px;
}

.bold {
  font-weight: bold;
}

.pro-button {
  background-color: #edf0f7;
  border: transparent;
}

.circle-img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.center-align {
  display: flex;
  align-items: center;
}

.active-link span {
  position: relative;
}

.active-link span::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -5px;
  height: 3px;
  background-color: var(--emotiva-pink);
}

@media (max-width: 963px) {
  #nav-collapse .navbar-nav {
    align-items: flex-start;
  }
  
  #nav-collapse .nav-item {
    width: 100%;
    padding: 0.5rem 0;
  }
  .circle-img{
    display: none;
  }
  
  
  .center-align {
    justify-content: flex-start;
  }
}

@media (min-width: 963px){
  
}

</style>