<template>
  <b-modal
    v-model="isModalVisible"
    size="md"
    dialog-class="mt-md-5 p-0"
    content-class="p-2"
    footer-class="border-top-0"
    header-class="border-bottom-0"
    @ok="submit"
    @cancel="cancel"
  >
    <template v-slot:modal-header>
      <h5 class="mb-0 text-uppercase font-weight-bold">
        {{ title }}
      </h5>
    </template>

    <template v-slot:default>
      <p class="font-weight-bold label">
        {{ bodyText }}
      </p>
      <b-form-input v-model="newInputString"> </b-form-input>
    </template>

    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button
        variant="white"
        class="mr-3 btn-blue-border justify-content-center align-items-center bold action-btn cancel"
        @click="cancel"
        >{{ $t("button.cancel") }}</b-button
      >
      <b-button
        variant="dark"
        class="justify-content-center align-items-center bold action-btn emotiva-pink-background"
        :disabled="!isFormValid"
        @click="ok"
        >{{ $t("button.ok") }}</b-button
      >
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    bodyText: {
      type: String,
      required: true,
    },
    modalStatus: {
      type: Boolean,
      required: true,
    },
    inputString: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      localInputString: this.inputString,
    };
  },
  watch: {
    inputString(newVal) {
      this.localInputString = newVal;
    },
    localInputString(newVal) {
      this.$emit("setInputString", newVal);
    },
  },
  computed: {
    isFormValid() {
      return this.localInputString.length > 0;
    },
    isModalVisible: {
      get() {
        return this.modalStatus;
      },
      set(modalStatus) {
        this.$emit("setModalStatus", modalStatus);
      },
    },
    newInputString: {
      get() {
        return this.localInputString;
      },
      set(inputString) {
        this.localInputString = inputString;
      },
    },
  },
  methods: {
    submit() {
      // create folder
      this.$emit("submit");
    },
    cancel() {
      this.localInputString = "";
      this.$emit("setInputString", "");
    },
  },
};
</script>

<style scoped>
.action-btn {
  border-radius: 26px;
  height: 46px;
  min-width: 100px;
}
.cancel{
  border: 2px solid #cbd2e0;
  /* Change border-width to 2px */
  border-color: #cbd2e0;
}
</style>
